import React, { useEffect, useState } from "react";
import Table from "./Table";

import { gql, useLazyQuery } from "@apollo/client";
import { Spin } from "antd";

import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment";
import { useHistory } from "react-router-dom";


interface Patient {
  firstName: string;
  lastName: string;
}

interface Physician {
  name: string;
  role: string;
}

interface ICare {
  readmissionAlertsOverview: {
    _id: string;
    patient: Patient;
    physician: Physician;
    timestamp: string;
    hl7: string;
  }[];
}

const DATA_READMISION_ALERTS = gql`
  query {
    readmissionAlertsOverview {
      _id
      patient{
        firstName
        lastName
      }
      physician{
        name
        role
      }
      hl7
      timestamp
      days_since_discharge

    }
  }
`;

export default function ReadmissionAlertsOverview() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [sortInput, setSortInput] = useState<object>({_id: -1})

  const { push } = useHistory();

  const [getReadmissionAlerts, { loading, error, data }] = useLazyQuery<ICare>(
    DATA_READMISION_ALERTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "Patient Name",
          "Physician Name",
          "Date"
        ]);

        completeData.readmissionAlertsOverview.forEach((value: any) => {
          tempArr.push([
            value._id,
            "value.name",
            "value.role"
          ]);
        });

        setDisplayData(tempArr);
      },
    }
  );


  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getReadmissionAlerts({
      variables: {
      },
    });
  };

  const getData = () => {
    getReadmissionAlerts({
      variables: {
      },
    });
  };

  const clearForm = () => {
    setSortInput({_id: -1})
    getDefaultData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  const buttonClickHandler = (tableRow: any) => {
    //console.log("email", tableRow[4]);
    //getToken({
    //  variables: {
    //    email: tableRow[4],
    //  },
    //});
  };

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSorting = (index: any) => {
    const sortOrder =
    index === sortField && order === "asc" ? "desc" : "asc";
    const sortHeader = ["_id","name","active","conditions"][index]
    const int_sort = (sortOrder === "asc" ? 1 : -1)
    const sortInput = {[sortHeader]: int_sort}
    setSortField(index);
    setOrder(sortOrder);
    getReadmissionAlerts({
      variables: {
      },
    });
   };

  return (
    <>
      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <FileUpload />
            <CSVLink
              data={displayData}
              filename={`CareConditions_${moment().format(
                "MM/DD/YYYY_HH:mm:ss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>
          <div className="flex-between search-filter-box">

            <div className="searchButtons">
              <button
                className="export-button clear"
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </button>
              <button
                className="export-button"
                onClick={() => {
                  getData();
                }}
              >
                Search
              </button>
            </div>
          </div>
          <Table
            headers={[
              "HL7 ID",
              "Patient name",
              "User Name",
              "User Role",
              "Timestamp"
            ]}
            headersSortBool={[
              true,
              true,
              true,
              true
            ]}
            data={data?.readmissionAlertsOverview.map((value) => [
              value.hl7,
              value.patient?`${value.patient.lastName}, ${value.patient.firstName}`:"UNKNOWN",
              value.physician?`${value.physician.name}`:"UNKNOWN",
              value.physician?`${value.physician.role}`:"UNKNOWN",
              value.timestamp
            ])}
            buttonClick={buttonClickHandler}
            handleSorting={handleSorting}
            order={order}
            field={sortField}
          />
        </>
      )}
    </>
  );
}
