import React, { useEffect, useState } from "react";

import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Spin } from "antd";
import Table from "./Table";
import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment";

interface ICondition {
  name: string| null;
}
interface IPhysician {
    name: string| null;
    NPI: string| null;
}
interface IPatients {
  patientsOverview: {
    _id: string | null;
    patientName: string | null;
    name: string | null;
    surname: string | null;
    dob: string | null
    visitNumber: string | null
    physicianNPI: string | null
    physicianName: string | null
    physicianId: string | null
    initialDischargeDate: string | null
    contact: string | null
    mobileNumber: string | null
    gender: string | null
    dischargeDate: string | null
    address: string | null
    state: string | null
    zip: string | null
    ssn: string | null
    type: string | null
    admitDate: string | null
    condition: ICondition | null
  }[];
}

const DATA_PATIENTS = gql`
  query ($patientName: String!, $NPI: String!, $Condition: String!, $sortInput: PatientsSort!) {
    patientsOverview(
      patientsName: $patientName
      NPI: $NPI
      Condition: $Condition
      sort: $sortInput
    ) {
      _id
      patientName
      surname
      dob
      visitNumber
      initialDischargeDate
      contact
      mobileNumber
      gender
      dischargeDate
      address
      state
      zip
      ssn
      type
      admitDate
      physicianName
      physicianNPI
      physicianId
      condition{
        name
      }
    }
  }
`;

export default function Patients() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [patientName, setPatientName] = useState("");
  const [Condition, setCondition] = useState("");
  const [sortInput, setSortInput] = useState<object>({patientName: -1})
  const [NPI, setNPI] = useState("");


  const [getPatients, { loading, error, data }] = useLazyQuery<IPatients>(
    DATA_PATIENTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "Patient ID",
          "Patient name",
          "Patient surname",
          "DOB",
          "NPI",
          "Visit number",
          "Physician",
          "Initial Discharge date",
          "Physician ID",
          "Contact",
          "Mobile Number",
          "Gender",
          "Discharge Date",
          "Address",
          "State",
          "Zip",
          "SSN",
          "Type",
          "Admit Date",
          "Condition",
        ]);

        completeData.patientsOverview.forEach((value) => {
          tempArr.push([
            value._id,
            value.patientName,
            value.surname,
            value.dob,
            value.physicianNPI?value.physicianNPI:"Not found",
            value.visitNumber,
            value.physicianName?value.physicianName:"Not found",
            value.initialDischargeDate,
            value.physicianId?value.physicianId:"Not found",
            value.contact,
            value.mobileNumber,
            value.gender,
            value.dischargeDate,
            value.address,
            value.state,
            value.zip,
            value.ssn,
            value.type,
            value.admitDate,
            value.condition?value.condition.name:"No condition",
          ]);
        });
        console.log(completeData);
        setDisplayData(tempArr);
      },
    }
  );

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getPatients({
      variables: {
        patientName: "",
        NPI: "",
        Condition:"",
        sortInput:{patientName: -1}
      },
    });
  };

  const getData = () => {
    getPatients({
      variables: {
        patientName: patientName,
        NPI: NPI,
        Condition: Condition,
        sortInput: sortInput
      },
    });
  };

  const clearForm = () => {
    setPatientName("");
    setNPI("");
    setCondition("");
    setSortInput({patientName: -1})
    getDefaultData();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSorting = (index: any) => {
    const sortOrder =
    index === sortField && order === "asc" ? "desc" : "asc";
    const sortHeader = ["_id","patientName","visitNumber","dob","initialDischargeDate","physician", "NPI"][index]
    const int_sort = (sortOrder === "asc" ? 1 : -1)
    const sortInput = {[sortHeader]: int_sort}
    console.log("events", sortField, sortOrder)
    setSortField(index);
    setOrder(sortOrder);
    getPatients({
      variables: {
        patientName: patientName,
        NPI: NPI,
        Condition: Condition,
        sortInput: sortInput
      },
    });
   };

  return (
    <>
      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <FileUpload />
            <CSVLink
              data={displayData}
              filename={`Patients_${moment().format(
                "MM/DD/YYYY_HH:mm:ss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>
          <div className="flex-between search-filter-box">
            <div className="flex">
              <div>
                <span className="search-filter-label">
                  Search by patient name
                </span>
                <input
                  type="text"
                  className="search-filter-input"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div>
                <span className="search-filter-label">Search by NPI</span>
                <input
                  type="text"
                  className="search-filter-input"
                  value={NPI}
                  onChange={(e) => setNPI(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div>
                <span className="search-filter-label">Search by condition</span>
                <input
                  type="text"
                  className="search-filter-input"
                  value={Condition}
                  onChange={(e) => setCondition(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            <div className="searchButtons">
              <button
                className="export-button clear"
                onClick={() => {
                  clearForm();
                }}
              >
                Clear
              </button>
              <button
                className="export-button"
                onClick={() => {
                  getData();
                }}
              >
                Search
              </button>
            </div>
          </div>
          <Table
            headers={[
              "Patient ID",
              "Patient name",
              "Visit number",
              "DOB",
              "Discharge date",
              "Physician name",
              "NPI",
              "Condition",
            ]}
            headersSortBool={[
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              false
            ]}
            data={data?.patientsOverview.map((value) => [
              value._id,
              value.patientName,
              value.visitNumber,
              moment(value.dob).format("MM/DD/yyyy"),
              moment(value.initialDischargeDate).format("MM/DD/yyyy"),
              value.physicianName?value.physicianName:"Not found",
              value.physicianNPI?value.physicianNPI:"Not found",
              value.condition?value.condition.name:"No condition",
            ])}
            handleSorting={handleSorting}
            order={order}
            field={sortField}
          />
        </>
      )}
    </>
  );
}
