import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Avatar, Menu } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { LOG_OUT } from "../../../../lib/graphql/mutations/";
import { LogOut as LogOutData } from "../../../../lib/graphql/mutations/LogOut/__generated__/LogOut";
import {
  displaySuccessNotification,
  displayErrorMessage,
} from "../../../../lib/utils";
import { Viewer } from "../../../../lib/types";
import { useToastContext } from "../../../../core/components/Toast/ToastContext";

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}
const { Item, SubMenu } = Menu;

export const MenuItems = ({ viewer, setViewer }: Props) => {
  const { showToast } = useToastContext();
  const { push } = useHistory();

  const [logOut] = useMutation<LogOutData>(LOG_OUT, {
    onCompleted: (data) => {
      if (data && data.logOut) {
        setViewer(data.logOut);
        localStorage.removeItem("zilius-token");
        // displaySuccessNotification("You've successfully logged out!");
        showToast("success", "You've successfully logged out!");

        push("/");
      }
    },
    onError: (data) => {
      // displayErrorMessage(
      //   "Sorry! We weren't able to log you out. Please try again later!"
      // );
      showToast(
        "error",
        "Sorry! We weren't able to log you out. Please try again later!"
      );
    },
  });

  const handleLogOut = () => {
    logOut();
  };

  const subMenuLogin =
    viewer.id && viewer.avatar ? (
      // <SubMenu title={<Avatar src={viewer.avatar} />}>
      <SubMenu
        title={
          <>
            <UserOutlined type="user" />
            {viewer.name}
          </>
        }
      >
        <Item key="/user">
          <Link to={`/user/${viewer.id}`}>
            <UserOutlined type="user" />
            Profile
          </Link>
        </Item>
        <Item key="/">
          <div >
            Role: {viewer.role}
          </div>
        </Item>
        <Item key="/logout">
          <div onClick={handleLogOut}>
            <LogoutOutlined key="logout" />
            Log out
          </div>
        </Item>
      </SubMenu>
    ) : null;
  // (
  //   <Item>
  //     <Link to="/login">
  //       <Button type="primary">Sign In</Button>
  //     </Link>
  //   </Item>
  // )
  return (
    <Menu mode="horizontal" selectable={false} className="menu">
      {/* <Item key="/host">
        <Link to="/host">
          <HomeOutlined type="home" />
        </Link>
        Host
      </Item> */}
      {subMenuLogin}
    </Menu>
  );
};
