import React, { useCallback, useState } from "react";

import { useMutation, gql, useQuery } from "@apollo/client";

import { ReactComponent as CloseButton } from "../../styles/closeButton.svg";

export default function RestartPassword(): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);

  const showWarning = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const showWarning2 = () => {
    setShow2(true);

    setTimeout(() => {
      setShow2(false);
    }, 3000);
  };

  return (
    <div>
      <button onClick={showWarning}>Show warning</button>
      <button onClick={showWarning2}>Show error</button>
      {show ? (
        <div className="globalToast warning">
          <p>
            MIHIN password is going to <b>expire in 02/26/2022.</b> Please
            contact MiHIN to acquire new password.
          </p>
          <div style={{ cursor: "pointer" }}>
            <CloseButton />
          </div>
        </div>
      ) : null}
      {show2 ? (
        <div className="globalToast error">
          <p>
            MIHIN password has <b>expired on 02/26/2022.</b> Please contact
            MiHIN to acquire new password.
          </p>
          <div style={{ cursor: "pointer" }}>
            <CloseButton />
          </div>
        </div>
      ) : null}
    </div>
  );
}
