import React, { useState } from "react";
import Events from "./Events";
import Facility from "./Facility";
import Patients from "./Patients";
import Physicians from "./Users";
import Conditions from "./Conditions";
import CareConditions from "./CareOperators";
// import CreateUser from "../User/Create";
import ReadmissionAlertsOverview from "./ReadmisionAlerts";

interface Props {
  showReadmissionAlerts: Boolean;
}

export default function DataOverview({ showReadmissionAlerts }: Props) {
  const [screen, setScreen] = useState("Facility");

  const renderScreen = () => {
    switch (screen) {
      case "Facility":
        return <Facility />;
      case "Users":
        return <Physicians />;
      case "Patients":
        return <Patients />;
      case "Events":
        return <Events />;
      case "Conditions":
        return <Conditions />;
      // case "CreateUser":
      //   return <CreateUser />;
      case "ReadmissionAlerts":
        return <ReadmissionAlertsOverview />;
      default:
        return <Facility />;
    }
  };
  return (
    <div className="container">
      <div className="flex-between ">
        <h4 className="content-title">Data overview</h4>
      </div>

      <div className="content-menu">
        <ul>
          <li
            className={`${screen === "Facility" ? "content-menu-active" : null
              }`}
            onClick={() => setScreen("Facility")}
          >
            Facility
          </li>
          <li
            className={`${screen === "Conditions" ? "content-menu-active" : null}`}
            onClick={() => setScreen("Conditions")}
          >
            Conditions
          </li>
          <li
            className={`${screen === "Users" ? "content-menu-active" : null
              }`}
            onClick={() => setScreen("Users")}
          >
            Users
          </li>
          {/* <li
            className={`${screen === "CareConditions" ? "content-menu-active" : null}`}
            onClick={() => setScreen("CareConditions")}
          >
            Care Operators
          </li> */}
          <li
            className={`${screen === "Patients" ? "content-menu-active" : null
              }`}
            onClick={() => setScreen("Patients")}
          >
            Patients
          </li>
          <li
            className={`${screen === "Events" ? "content-menu-active" : null}`}
            onClick={() => setScreen("Events")}
          >
            Events
          </li>
          {(showReadmissionAlerts) ? (<li
            className={`${screen === "ReadmissionAlerts" ? "content-menu-active" : null}`}
            onClick={() => setScreen("ReadmissionAlerts")}
          >
            Alerts
          </li>) : null}
        </ul>
      </div>
      {renderScreen()}
    </div>
  );
}
