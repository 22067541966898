import React, { useCallback, useState } from "react";

import { useMutation, gql } from "@apollo/client";
import { useDropzone } from "react-dropzone";

import { ReactComponent as UploadIcon } from "../../../../styles/uploadIcon.svg";
import { ReactComponent as CloseButton } from "../../../../styles/closeButton.svg";

const UPLOAD_FILE = gql`
  mutation uploadFile($file: FileUpload!) {
    uploadFile(file: $file) {
      filename
    }
  }
`;

export default function FileUpload() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAcceptable, setIsAcceptable] = useState(false);
  const [error, setError] = useState("");
  const [myFiles, setMyFiles] = useState<any>([]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setError("");

    const rgx = /.*(facility|users|patients|careconditons|conditions).*\.(xls|xlsx|csv)/;

    // always should be 1 file in array
    if (!rgx.test(acceptedFiles[0].name)) {
      setError("File name not supported !");
      setIsAcceptable(false);
    } else if (fileRejections.length) {
      setError("File type not supported !");
      setIsAcceptable(false);
    } else {
      setMyFiles([...myFiles, ...acceptedFiles]);
      setIsAcceptable(true);
    }
  }, []);

  let { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept:
      "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE, {
    onCompleted: (data) => {
      setIsModalOpen(false);
    },
    onError: (data) => {
      console.log(data);
    },
  });

  const uploadButtonHandler = () => {
    console.log(myFiles);
    const file = myFiles[0];
    uploadFile({ variables: { file: file } });
  };

  const files = myFiles.map((file: any) => (
    <div key={file.path} className="FileList">
      <CloseButton
        onClick={() => {
          setMyFiles([]);
        }}
      />
      <span>
        {file.path} - {file.size} bytes
      </span>
    </div>
  ));

  return (
    <div>
      <button
        className="file-upload_button"
        onClick={() => setIsModalOpen((prev) => !prev)}
      >
        Upload
      </button>
      {isModalOpen ? (
        <div className="file-upload_modal">
          <div
            className="file-upload_modal_backdrop"
            onClick={() => setIsModalOpen((prev) => !prev)}
          ></div>
          <div className="file-upload_modal_box">
            <div
              onClick={() => setIsModalOpen((prev) => !prev)}
              style={{ cursor: "pointer" }}
            >
              <CloseButton />
            </div>
            <p>File upload</p>
            <span>Upload new file. Supported formats are:</span>
            <ul>
              <li>.csv, .xls, .xlsx</li>
            </ul>
            <span className="file-upload_error">{error}</span>

            {myFiles.length ? (
              files
            ) : (
              <div {...getRootProps({ className: "file-upload_dropzone" })}>
                <input {...getInputProps()} />

                <UploadIcon />
                <p>Drag and drop or click here</p>
              </div>
            )}

            {loading ? (
              <button disabled>Upload</button>
            ) : (
              <button onClick={uploadButtonHandler} disabled={!isAcceptable}>
                Upload
              </button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
