import React from "react";
import { NavLink } from "react-router-dom";
import { Card, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { iconColor } from "../../utils";
import { User_Events_user_events_result } from "../../../lib/graphql/queries/Events/__generated__/User_Events";

interface EventCardProps {
  event: User_Events_user_events_result;
}

const { Text, Title } = Typography;

export function EventCard({ event }: EventCardProps) {
  const { id, eventTime, eventFacility, admissionDiagnosis, eventType } = event;

  return (
    <NavLink to={`/events`}>
      <Card hoverable>
        <div className="listing-card__details">
          <div className="listing-card__description">
            <Title level={4} className="listing-card__price">
              {eventType}
              <span>/day</span>
            </Title>
            <Text strong ellipsis className="listing-card__title">
              {eventTime}
            </Text>
            <Text ellipsis className="listing-card__address">
              {eventFacility}
            </Text>
          </div>
          <div className="listing-card__dimensions listing-card__dimensions--guests">
            <UserOutlined style={{ color: iconColor }} />
            <Text>{admissionDiagnosis} diagnosis</Text>
          </div>
        </div>
      </Card>
    </NavLink>
  );
}
