import React, { useEffect, useState } from "react";
import Table from "./Table";
import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import { useLazyQuery, gql } from "@apollo/client";
import moment from "moment";
import { Spin } from "antd";

const DATA_CONDITIONS = gql`
  query ($sortInput: ConditionsSort) {
    conditionsOverview(
      sort: $sortInput
      ){
      results {
        id
        name
      }
    }
  }
`;

export default function Condition() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [sortInput, setSortInput] = useState<object>({id: -1})
  const [clearLodaing, setClearLodaing] = useState(false);

  const [getCondition, { loading, error, data }] = useLazyQuery<any>(
    DATA_CONDITIONS,
    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "ID",
          "Name"
        ]);

        completeData.conditionsOverview.results.forEach((value: any) => {
          tempArr.push([
            value.id,
            value.name
          ]);
        });
        setDisplayData(tempArr);
        setClearLodaing(false);
      },
    }
  );

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getCondition({
      variables: {
        sortInput: {id: -1}
      },
    });
  };

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSorting = (index: any) => {
    const sortOrder =
    index === sortField && order === "asc" ? "desc" : "asc";
    const sortHeader = ["id", "name"][index]
    const int_sort = (sortOrder === "asc" ? 1 : -1)
    const sortInput = {[sortHeader]: int_sort}
    setSortField(index);
    setOrder(sortOrder);
    getCondition({
      variables: {
        sortInput: sortInput
      }
    })
   };

  return (
    <>
      {/* <button className="export-button">Export</button> */}

      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            {/* <FileUpload /> */}
              <CSVLink
                data={displayData}
                filename={`Conditions_${moment().format(
                  "MM/DD/YYYY_HH:mm:ss"
                )}.csv`}
                className="export-button"
              >
                Export
              </CSVLink>
          </div>
          <Table
            headers={[
              "ID",
              "Condition"
            ]}
            headersSortBool={[
              false,
              true,
            ]}
            data={data.conditionsOverview.results.map((value: any) => [
              value.id,
              value.name
            ])}
            handleSorting={handleSorting}
            order={order}
            field={sortField}
          />
        </>
      )}
    </>
  );
}
