import React, { useState } from "react";
import { PageSkeleton, ErrorBanner } from "../../lib/components";
import { Redirect } from "react-router-dom";

import { Row, Col, Layout, Typography } from "antd";
import { useQuery } from "@apollo/client";

import { USER_EVENTS } from "../../lib/graphql/queries";
import {
  User_Events as User_EventsData,
  User_EventsVariables,
} from "../../lib/graphql/queries/Events/__generated__/User_Events";
import { Viewer } from "../../lib/types";
import { UserEvents } from "./components";
import FileUpload from "./components/FileUpload/FileUpload";
import { useToastContext } from "../../core/components/Toast/ToastContext";

const { Paragraph } = Typography;

const { Content } = Layout;

interface Props {
  viewer: Viewer;
}
const PAGE_LIMIT = 20;

export const Events = ({ viewer }: Props) => {
  const [eventsPage, setEventsPage] = useState(1);
  const { showToast } = useToastContext();
  const viewer_id = viewer.id ? viewer.id : " ";
  const { data, loading, error } = useQuery<
    User_EventsData,
    User_EventsVariables
  >(USER_EVENTS, {
    variables: {
      id: viewer_id,
      eventsPage,
      limit: PAGE_LIMIT,
    },
  });

  if (loading) {
    return (
      <Content className="user">
        <PageSkeleton></PageSkeleton>
      </Content>
    );
  }
  console.log(error)
  console.log(data)
  if (error) {
    // commented to start app RUN APP
    if(data === undefined){ //check if there is no data from backend log-in
      localStorage.removeItem("zilius-token");
      //showToast("error", "User is possibly deactivated. Contact your Admin regarding access.")
      return (
        <Redirect to={`/login`}>
          <ErrorBanner description="User is possibly deactivated. Contact your Admin regarding access." />
        </Redirect>
      );
    }
    else return <>Need to fix!</>
    return (
      <Redirect to={`/login`}>
        <ErrorBanner description="This user may not exist or we've encountered an error. Please try again soon." />
      </Redirect>
    );
  }


  const user = data ? data.user : null;
  const userEvents = user ? user.events : null;

  if (viewer.id) {
    const userEventsElement = userEvents ? (
      <UserEvents
        userEvents={userEvents}
        eventsPage={eventsPage}
        limit={PAGE_LIMIT}
        setEventsPage={setEventsPage}
        viewer={viewer}
      />
    ) : null;
    return (
      <Content className="user">
        <Row gutter={12} justify="space-between">
          <Col xs={24}>{userEventsElement}</Col>
        </Row>
      </Content>
    );
  } else {
    return (
      <Redirect to={`/login`}>
        <ErrorBanner description="Viewer does not exist! Please try again soon!" />
      </Redirect>
    );
  }
};
