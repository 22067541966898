import { useQuery, gql } from "@apollo/client";
import { Spin } from "antd";
import React, { useState } from "react";

const READMISSION_ALERTS = gql`
  query ($year: String!) {
    readmissionAlerts(year: $year) {
      resaults {
        month
        count
      }
    }
  }
`;

const READMISSION = gql`
  query ($year: String!) {
    readmission(year: $year) {
      resaults {
        month
        count
      }
    }
  }
`;

const DEFAULT_YEAR = new Date().getFullYear();

export default function ReadmissionAlerts() {
  const [screen, setScreen] = useState("first");
  const [year, setYear] = useState(DEFAULT_YEAR.toString());
  const {
    data: readmissionAlertData,
    loading: isReadmissionAlertLoading,
    error: errorReadmissionAlert,
  } = useQuery(READMISSION_ALERTS, {
    variables: {
      year: year,
    },
  });

  const {
    data: readmissionData,
    loading: isReadmissionLoading,
    error: errorReadmission,
  } = useQuery(READMISSION, {
    variables: {
      year: year,
    },
  });

  const renderScreen = () => {
    let screenForRender = null;

    switch (screen) {
      case "first":
        screenForRender = (
          <>
            <label htmlFor="yearSelect" id="yearSelectLabel" className="t1">
              Select year
            </label>
            <select
              name="yearSelect"
              id="yearSelect"
              onChange={(e) => setYear(e.target.value)}
              value={year}
            >
              {/* create array of last 20 years */}
              {Array.from({ length: 5 }, (_, i) => {
                return (
                  <option key={i} value={DEFAULT_YEAR - i}>
                    {DEFAULT_YEAR - i}
                  </option>
                );
              })}
            </select>
            {isReadmissionLoading ? (
              <div className="flex-center" style={{ margin: "10px 0" }}>
                <Spin size="small" />
              </div>
            ) : (
              <div className="RA-months_list">
                {readmissionData?.readmission?.resaults.map(
                  (value: any, index: number) => (
                    <div className="RA-month_box" key={index}>
                      <div className="RA-month_header flex-center t1">
                        {value.month}
                      </div>
                      <div className="RA-month_title flex-center t1">
                        {value.count}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </>
        );
        break;

      case "second":
        screenForRender = (
          <>
            <label htmlFor="yearSelect" id="yearSelectLabel" className="t1">
              Select year
            </label>
            <select
              name="yearSelect"
              id="yearSelect"
              onChange={(e) => setYear(e.target.value)}
              value={year}
            >
              {/* create array of last 20 years */}
              {Array.from({ length: 5 }, (_, i) => {
                return (
                  <option key={i} value={DEFAULT_YEAR - i}>
                    {DEFAULT_YEAR - i}
                  </option>
                );
              })}
            </select>
            {isReadmissionAlertLoading ? (
              <div className="flex-center" style={{ margin: "10px 0" }}>
                <Spin size="small" />
              </div>
            ) : (
              <div className="RA-months_list">
                {readmissionAlertData?.readmissionAlerts?.resaults.map(
                  (value: any, index: number) => (
                    <div className="RA-month_box" key={index}>
                      <div className="RA-month_header flex-center t1">
                        {value.month}
                      </div>
                      <div className="RA-month_title flex-center t1">
                        {value.count}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </>
        );
        break;

      default:
        break;
    }

    return screenForRender;
  };

  return errorReadmissionAlert || errorReadmission ? (
    <>Error: {errorReadmissionAlert?.message ?? errorReadmission?.message}</>
  ) : (
    <div>
      <div className="RA-Header_nav">
        <span
          className={screen === "first" ? "RA-Header_active" : ""}
          onClick={() => setScreen("first")}
        >
          Readmission per month
        </span>
        <span
          className={screen === "second" ? "RA-Header_active" : ""}
          onClick={() => setScreen("second")}
        >
          Sent SMS Messages
        </span>
      </div>
      {renderScreen()}
    </div>
  );
}
