import React from "react";
import { Tag, Row, Col, List, Typography } from "antd";
import ReadmissionAlerts from "../Events/components/ReadmissionAlerts/ReadmissionAlerts";
const { Title } = Typography;

export default function SimpleReporting() {
  return (
    <div className="container">
      {" "}
      <div className="user-events_header">
        <div>
          <Title level={4} className="user-events__title">
            Readmission Alerts
          </Title>
          {/* {viewer.default ? <ReadmissionAlerts /> : null} */}
          <ReadmissionAlerts />
        </div>

        {/* {viewer.default ? <FileUpload /> : null} */}
      </div>
    </div>
  );
}
