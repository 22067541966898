import { gql } from "apollo-boost";

export const USER_EVENTS = gql`
  query User_Events($id: ID!, $eventsPage: Int!, $limit: Int!) {
      user(id: $id) {
        id
        name
        avatar
        contact
          events(limit: $limit, page: $eventsPage) {
            total
            result{
              id
              eventTime
              eventFacility {
                id
                facilityName
                facilityCode
                facilityContact
              }
              eventType
              admissionDiagnosis
              admissionType
              patient{
                firstName
                lastName
                contact
                type
                initialDischargeDate
                dob
                gender
              }
              physician{
                  name
                  NPI
                  contact
              }
            }
          }
        }
    }
`;