import React, { useEffect, useState } from "react";
import Table from "./Table";

import { useLazyQuery, gql } from "@apollo/client";
import { Spin } from "antd";

import { CSVLink } from "react-csv";
import FileUpload from "../Events/components/FileUpload/FileUpload";
import moment from "moment";
import { StringLiteral } from "typescript";

const DATA_FACILITY = gql`
  query ($sortInput: FacilitySort) {
    facilityOverview(
      sort: $sortInput
      ){
      results {
        id
        facilityName
        facilityCode
        facilityContact
        PONAME
        healthSystemCode
        facilityDirectAddress
      }
    }
  }
`;

export default function Facility() {
  const [displayData, setDisplayData] = useState<any>([]);
  const [sortInput, setSortInput] = useState<object>({id: -1})
  const [clearLodaing, setClearLodaing] = useState(false);

  const [getFacility, { loading, error, data }] = useLazyQuery<any>(
    DATA_FACILITY, 
    {
      fetchPolicy: "network-only",
      onCompleted: (completeData) => {
        const tempArr = [];
        tempArr.push([
          "Facility ID",
          "Facility name",
          "Facility PO name",
          "Facility code",
          "Organization code",
          "Practice direct address",
          "Facility phone",
        ]);

        completeData.facilityOverview.results.forEach((value: any) => {
          tempArr.push([
            value.id,
            value.facilityName,
            value.PONAME,
            value.facilityCode,
            value.healthSystemCode,
            value.facilityDirectAddress,
            value.facilityContact,
          ]);
        });

        setDisplayData(tempArr);
        setClearLodaing(false);
      },
    }
  );

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = () => {
    getFacility({
      variables: {
        sortInput: {id: -1}
      },
    });
  };

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSorting = (index: any) => {
    const sortOrder =
    index === sortField && order === "asc" ? "desc" : "asc";
    const sortHeader = ["id", "facilityName", "PONAME", "facilityCode", "healthSystemCode", "facilityDirectAddress", "facilityContact"][index]
    const int_sort = (sortOrder === "asc" ? 1 : -1)
    const sortInput = {[sortHeader]: int_sort}
    setSortField(index);
    setOrder(sortOrder);
    getFacility({
      variables: {
        sortInput: sortInput
      }
    })
   };

  return (
    <>
      {/* <button className="export-button">Export</button> */}

      {loading || data === undefined ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <div className="exportuploadbuttonposition">
            <FileUpload />
            <CSVLink
              data={displayData}
              filename={`Facility_${moment().format(
                "MM/DD/YYYY_HH:mm:ss"
              )}.csv`}
              className="export-button"
            >
              Export
            </CSVLink>
          </div>

          <Table
            headers={[
              "Facility ID",
              "Facility name",
              "Facility PO name",
              "Facility code",
              "Organization code",
              "Practice direct address",
              "Facility phone",
            ]}
            headersSortBool={[
              false,
              true,
              true,
              false,
              false,
              true,
              false
            ]}
            data={data.facilityOverview.results.map((value: any) => [
              value.id,
              value.facilityName,
              value.PONAME,
              value.facilityCode,
              value.healthSystemCode,
              value.facilityDirectAddress,
              value.facilityContact,
            ])}
            handleSorting={handleSorting}
            order={order}
            field={sortField}
          />
        </>
      )}
    </>
  );
}
