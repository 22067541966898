import { useToastContext } from "./ToastContext";

import { ReactComponent as CloseButton } from "../../../styles/closeButton.svg";

export default function Toast(): JSX.Element {
  const { show, closeToast, type, text } = useToastContext();

  return (
    <>
      {show ? (
        <div className={`globalToast ${type}`}>
          <p>{text}</p>

          <div style={{ cursor: "pointer" }} onClick={closeToast}>
            <CloseButton />
          </div>
        </div>
      ) : null}
    </>
  );
}
