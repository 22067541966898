import { gql } from "@apollo/client";

export const LOG_IN_AWS = gql`
  mutation LogIn_AWS($input: LogInInput) {
    logIn_aws(input: $input) {
      id
      token
      avatar
      name
      active
      role
      didRequest
    }
  }
`;
